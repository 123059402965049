<!--  -->
<template>
  <div>
    <div class="buy-top">
        <img src="../../static/background11.png" alt="">
    </div> 
    <div class="buy-title">
        <div>购票信息</div>
    </div>
    <div class="buy-info">
        <span class="label">手机号</span>
        <van-field v-model="phone" placeholder="请输入手机号" maxlength="11" class="buy-info-input" style="box-sizing: content-box;"/>
    </div>
    <div class="buy-info" @click="showPicker = true">
        <span class="label">购买类型</span>
        <div class="buy-info-input">
            <span>{{buyType}}</span>
            <van-icon name="arrow" />
        </div>
    </div>
    <div class="buy-info"  @click="showTime = true">
        <span class="label">入园日期</span>
        <div class="buy-info-input">
            <span>{{buyTime}}</span>
            <van-icon name="arrow" />
        </div>
    </div>
    <div class="buy-info">
        <span class="label">购票数量</span>
        <div class="buy-info-num">          
            <img src="../../static/jia.png" class="buy-info-jia" @click="add">
            <span>{{buyNum}}</span>
            <img src="../../static/jian.png" class="buy-info-jian" @click="subtract">
        </div>
    </div>
    <div class="buy-user buy-info" >
        <van-field v-model="buyUID" label="购买人身份证"   maxlength="18" placeholder="请输入" />
        <van-field v-model="buyName" label="购买人姓名" maxlength="5" placeholder="请输入" />      
    </div>
    <div class="buy-div"></div>
    <div class="buy-btn">
        <span>金额</span>
        <i style="color:#FF3B30;letter-spacing: 1px;">¥{{money}}</i>
        <span @click="buyBtn">提交</span>
    </div>
    <!-- 弹出层 -->
    <van-popup v-model="showPicker" position="bottom">
        <van-picker
            show-toolbar
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
        />
    </van-popup>
    <van-popup v-model="showTime" position="bottom">
        <van-datetime-picker
            v-model="currentDate"
            :min-date="minDate"
            type="date"
            @cancel="showTime = false"
            @confirm="ConfirmTime"
        />
    </van-popup>
    <van-dialog 
        v-model="showInfo"
        show-cancel-button  
        @cancel="showInfo = false"
        @confirm="buySubmit"
        class="buy-dialog"
        >
        <div class="info-list">
            <div class="info-li">
                <span>购票手机号</span>
                <span>{{phone}}</span>
            </div>
            <div class="info-li">
                <span>购票类型</span>
                <span>{{buyType}}</span>
            </div>
            <div class="info-li">
                <span>购票数量</span>
                <span>{{buyNum}}</span>
            </div>
            <div class="info-li">
                <span>入园日期</span>
                <span>{{buyTime}}</span>
            </div>
            <div class="info-li">
                <span>金额</span>
                <span style="color:#FF3B30;letter-spacing: 1px;">¥ {{money}}</span>
            </div>
        </div>
        
    </van-dialog>

  </div>
</template>

<script>
import { Field ,Icon ,Popup ,Picker,DatetimePicker ,Toast,Dialog     } from 'vant';
import wx from 'weixin-js-sdk'
export default {
    data () {
        return {
            info:{
                content:{}
            },
            open:'01',
            topicSymbol:'min-online-info-list',
            openId:'',
            phone:'',
            buyType:'廊桥景区',
            buyTime:'',
            buyNum:1,
            buyName:'',
            buyUID:'',
            showPicker:false,
            showTime:false,
            showInfo:false,
            columns: ['廊桥景区', '氡泉景区'],
            currentDate: new Date(),
            minDate:new Date(),
            unitPrice:0,
            ticketPrice1:0,
            ticketPrice2:0,
            productSn:'',
        };
    },
    computed: {
        money:function(){
            return (this.buyNum*this.unitPrice).toFixed(2);               
        }
    },
    components: {
        [Field.name]:Field,
        [Icon.name]:Icon ,
        [Popup.name]:Popup ,
        [Picker.name]:Picker ,
        [DatetimePicker.name]:DatetimePicker ,
        [Toast.name]:Toast ,
        [Dialog.Component.name]: Dialog.Component,
    },
    created() {
        this.openId = this.$route.query.openId;
        // Toast(this.openId);
        this.buyType = this.$route.query.buyType;  
        if(this.buyType=='廊桥景区'){
            this.productSn='7753362845345_2548';
        }else{
            this.productSn='7753362845346_2548';
        }   
        this.init();
    },
    methods: {
        init(){
            // 得到当前时间
            var date = new Date();
            var m = date.getMonth() + 1;
            var d = date.getDate();
            if (m >= 1 && m <= 9) {
                m = "0" + m;
            }
            if (d >= 0 && d <= 9) {
                d = "0" + d;
            }
            var timer = date.getFullYear() + "年" + m + "月" + d +'日';
            this.buyTime = timer;
            // 获取票价    
            this.$ajax.post('https://wx.tslbrsr.com/tb/getTicket/queryPrice')
            .then(res=> {
                console.log(res.data.data)
                // 成功
                if(res.data.code==200){
                    res.data.data.forEach(e => {
                        console.log(e.scenicName.indexOf('廊桥景区'))
                        if(e.scenicName.indexOf('廊桥景区')!='-1'){
                            this.ticketPrice1=e.ticketPrice;
                        }
                        if(e.scenicName.indexOf('氡泉景区')!='-1'){
                            this.ticketPrice2=e.ticketPrice;
                        }
                    });
                    if(this.buyType=='廊桥景区'){
                        this.unitPrice=this.ticketPrice1;
                    }else{
                        this.unitPrice=this.ticketPrice2;
                    }
                }else{
                    Toast('获取价格失败！');
                }
                
            })
            .catch( (error)=> {
                console.log(error);
            }); 
        },
        onConfirm(value) {
            this.buyType = value;
            if(this.buyType=='廊桥景区'){
                this.unitPrice=this.ticketPrice1;
                this.productSn='7753362845345_2548';
            }else{
                this.unitPrice=this.ticketPrice2;
                this.productSn='7753362845346_2548';
            }
            this.showPicker = false;
        },
        ConfirmTime(value){
            var date = value;
            var m = date.getMonth() + 1;
            var d = date.getDate();
            if (m >= 1 && m <= 9) {
                m = "0" + m;
            }
            if (d >= 0 && d <= 9) {
                d = "0" + d;
            }
            var timer = date.getFullYear() + "年" + m + "月" + d +'日'
            this.buyTime = timer;
            this.currentDate = value;
            this.showTime = false;
        },
        subtract(){
            if(this.buyNum<=1){
                return
            }else{
                this.buyNum -= 1;
            }
        },
        add(){
            if(this.buyNum>=50){
                return
            }else{
                this.buyNum += 1;
            }
        },
        buyBtn(){
            if(this.phone==''){
                return Toast('请输入手机号');
            }else if(!(/^1[345678]\d{9}$/.test(this.phone))){
                return Toast('请输入正确的手机号');
            } else if(this.buyName==''){
                return Toast('请输入姓名');
            } else if(this.buyUID==''){
                return Toast('请输入身份证');
            }else if(!( /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(this.buyUID))){
                return Toast('请输入正确身份证号');
            }
            // 弹出框
            this.showInfo = true;
        },
        buySubmit(){
            console.log('提交')
            let time =  '';
            time= this.buyTime.replace('年', '-');              
            time= time.replace('月', '-');      
            wx.miniProgram.navigateTo({
                url:'/pages/pay_ticket/pay_ticket?openId='+this.openId+
                    '&mobile='+this.phone+
                    '&ticketType='+this.buyType+
                    '&ticketNumber='+this.buyNum+
                    '&consignee='+this.buyName+
                    '&consigneeId='+this.buyUID+
                    '&orderPrice='+this.money+
                    '&ticketDate='+time+
                    '&productSn='+this.productSn,
                success: function(){
                    console.log('success')
                },
                fail: function(){
                    console.log('fail');
                },
                complete:function(){
                    console.log('complete');
                }
            });   
            // wx.miniProgram.navigateTo({
            //     url:'/pages/pay/pay?orderId=1',
            //     success: function(){
            //         console.log('success')
            //     },
            //     fail: function(){
            //         console.log('fail');
            //     },
            //     complete:function(){
            //         console.log('complete');
            //     }
            // });                   
            // this.$ajax.post('https://wx.tslbrsr.com/tb/order/create', {
            //     openId: this.openId,
            //     mobile:this.phone,
            //     ticketType:this.buyType,
            //     ticketNumber:this.buyNum,
            //     consignee:this.buyName,
            //     consigneeId:this.buyUID,
            //     orderPrice:this.money,
            //     ticketDate:time,
            //     productSn:this.productSn
            // }).then(res=> {
            //     // 成功
            //     if(res.data.code=='200'){
            //         console.log('成功');
                    
            //         // this.$router.push({
            //         //     path: "/success", 
            //         //     query: {
            //         //         type: 1
            //         //     }             
            //         // });
            //     }else{
            //         Toast(res.data.msg);
            //     }
                
            // })
            // .catch( (error)=> {
            //     console.log(error);
            // });   
        }
    },
        
    
}

</script>
<style scoped>
.buy-top{
  width: 10rem;
  height: 9.49rem;
}
.buy-top img{
    width: 100%;
    height: 100%;
}
.buy-title{
    width: 10rem;
    height: 1.28rem;
    box-sizing: border-box;
    padding: 0 0.426rem;
    font-size: 0.426rem;
    line-height: 1.28rem;
    color:#222222 ;
    text-indent: 0.213rem;
    position: relative;
}
.buy-title div::before{
    content: "";
    width: 0.08rem;
    height: 0.4rem;
    background:#00A5FF ;
    position: absolute;
    left: 0.426rem;
    top: 0.45rem;
}
.buy-info{
    width: 10rem;
    padding:0.08rem 0; 
    height: 1.17rem;
}
.buy-info-input{
    width:6rem;
    float: right;
    height: 0.64rem;
    padding: 0.26667rem 0.42667rem;
    position: relative;;
}
.buy-info-input span{
    font-size: 0.373rem;
    line-height: 0.587rem;
    color: #222222;
    text-align: right;
    margin-right: 0.53rem;
    float: right;
}
.buy-info-input i{
    font-size: 0.426rem;
    position:absolute;
    right: 0.426rem;
    top: 0.373rem;
    color: #BBBBBB;
}
.buy-info .label{
    font-size: 0.373rem;
    line-height: 1.17rem;
    color: #888;
    float: left;
    margin-left: 0.426rem;
}
.buy-info-num{
    width:6rem;
    float: right;
    height: 0.64rem;
    padding: 0.26667rem 0.42667rem;
    position: relative;
    text-align: right;
}
.buy-info-num img{
    width: 0.587rem;
    height: 0.587rem;
    float: right;
}
.buy-info-num span{
    width: 1.067rem;
    display: inline-block;
    columns: #000000;
    font-size: 0.373rem;
    line-height: 0.587rem;
    float: right;
    text-align: center;
}
.buy-user{
    width: 10rem;
    padding:0.373rem 0.426rem ;
    box-sizing: border-box;
    height: 3.15rem;
}
.buy-btn{
    width: 10rem;
    height: 1.706rem;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background: #fff;
}
.buy-btn span:first-child{
    color: #222;
    font-size: 0.32rem;
    line-height:  1.706rem;
    float: left;
    margin: 0 0.15rem 0 0.426rem; 
}
.buy-btn i{
    color: #FF3B30;
    font-size: 0.53rem;
    line-height:  1.706rem;
    float: left;
    font-style:normal; 
}
.buy-btn span:last-child{
    float: right;
    width:6.4rem ;
    height:1.173rem ;
    color: #fff;
    background: #00A5FF;
    border-radius: 0.106rem;
    text-align: center;
    font-size: 0.426rem;
    line-height:  1.173rem;
    margin: 0.26rem 0.426rem 0 0; 
}
.info-list{
    width:6.34rem; 
    padding-left:0.85rem;
}
.info-li{
    height: 0.533rem;
    margin-top: 0.373rem;
    font-size: 0.373rem;
    line-height: 0.533rem;
}
.info-li span:first-child{
    width: 2.4rem;
    display: inline-block;
    color: #888888;
}
.info-li span:last-child{
    color: #222222;
}
.info-li:first-child{
    margin-top: 0.8rem;
}
.info-li:last-child{
    margin-bottom: 0.4rem;
}
.buy-div{
    width: 100%;
    height: 1.706rem;
}
</style>